import * as icons from 'assets/icons'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Icon.module.scss'

const styles = fromModule(css)

export type IconKey = keyof typeof icons

export const Icon: React.FC<{
	icon: IconKey
	color?: string
}> = ({icon, color}) => {
	if (!icons[icon]) {
		console.warn(`Undefined icon key: ${icon}`)
		return null
	}

	const Component = icons[icon].default
	return <Component className={styles.icon()} style={{fill: color}} />
}
