export const nl = {
	lang: 'nl' as const,
	project_name: "Flanders' FOOD",
	overview: {
		searchbox: {
			placeholder: 'Zoek op trefwoord'
		},
		filters: {
			title: 'Filters',
			clear: 'Filters wissen',
			tags: 'Keywords'
		},
		pagination: {
			of: 'van',
			previous: 'vorige',
			next: 'volgende'
		}
	},
	programs: {
		discover: 'Ontdek het programma en de projecten'
	},
	contact: {
		back: 'Terug naar contact pagina',
		more_info: 'Meer info of interesse?',
		send_mail: 'Stuur een e-mail'
	},
	projects: {
		tab_running: 'Lopende projecten',
		tab_finished: 'Afgelopen projecten',
		categories: "Programma's en roadmaps",
		region: 'Type',
		domains: 'Strategisch domein'
	},
	project: {
		load_more: 'Laad meer projecten',
		show_all: 'Toon alle projecten',
		discover: 'Ontdek project',
		type: 'Project type',
		start_date: 'Start',
		end_date: 'Eind',
		resultsLabel: (nr: number) => (nr === 1 ? '1 project' : `${nr} projecten`),
		share: 'Deel dit project'
	},
	calendar: {
		tab_flandersfood: "Flanders' FOOD activiteiten",
		tab_all: 'Alle activiteiten uit de cluster',
		all: 'Alles',
		this_week: 'Deze week',
		this_month: 'Deze maand',
		next_month: 'Volgende maand',
		finished: 'Afgelopen',
		branding: 'Branding',
		regions: 'Locatie',
		dates: 'Datum',
		price: 'Prijs'
	},
	events: {
		show_all: 'Toon alle activiteiten',
		brandings: 'Branding',
		pricings: 'Prijzen',
		resultsLabel: (nr: number) =>
			nr === 1 ? '1 activiteit' : `${nr} activiteiten`
	},
	event: {
		program: 'Programma',
		practicals: {
			title: 'Praktische informatie',
			when: 'Wanneer',
			where: 'Waar',
			budget: 'Tarief',
			language: 'Taal',
			organisor: 'Organisator'
		},
		subscribe: 'Schrijf je in',
		share: 'Deel dit event',
		branding: {
			icon: 'Event branding icoon'
		}
	},
	article: {
		author: 'Auteur',
		published_on: 'Gepubliceerd op',
		show_all: 'Alle artikels',
		share: 'Deel dit artikel'
	},
	concept: {
		readmore: 'Lees meer'
	},
	product: {
		show_all: 'Toon alle producten',
		price: 'Prijs',
		subtext: `Prijzen zijn exclusief BTW en inclusief verzendingskosten binnen de EU`,
		add: 'Bestel product',
		added: 'Toegevoegd',
		show_in_basket: (quantity: number) => `Bekijk in winkelmandje (${quantity})`
	},
	shop: {
		show_basket: 'Bekijk winkelwagen',
		is_added: 'is toegevoegd aan uw winkelwagen.'
	},
	basket: {
		no_products: 'U heeft momenteel geen producten in uw winkelwagen.',
		excl_vat: '(excl. BTW)',
		show: 'Bekijk winkelwagen',
		delete: 'Verwijder uit winkelwagen',
		total: 'Totaal',
		info: 'Prijzen zijn exclusief BTW en inclusief verzendingskosten binnen de EU',
		submit: 'Bestelling afronden'
	},
	newsletter: {
		placeholder: 'E-mailadres',
		button: 'Schrijf je in'
	},
	homepage: {
		arialabel: 'Ga naar de startpagina'
	},
	search: {
		arialabel: 'Ga naar de zoekpagina'
	},
	mobilemenu: {
		open: 'Open mobiel menu',
		close: 'Sluit mobiel menu'
	},
	carousel: {
		gotoprev: 'Ga naar de vorige',
		gotonext: 'Ga naar de volgende'
	},
	cookies: {
		optout: {
			text: (category: string) =>
				`Accepteer ${category}-cookies om deze content weer te geven.`,
			button: 'Cookie-instellingen'
		}
	},
	error404: {
		title: 'Pagina niet gevonden',
		text: `We kunnen de pagina die je zoekt niet vinden. Deze is vermoedelijk hernoemd, verplaatst, verwijderd of heeft misschien nooit bestaan.`,
		btnlabel: 'Ga naar startpagina'
	},
	dates: {
		at: 'om',
		from: 'van',
		to: 'tot'
	},
	months: [
		'januari',
		'februari',
		'maart',
		'april',
		'mei',
		'juni',
		'juli',
		'augustus',
		'september',
		'oktober',
		'november',
		'december'
	]
}
export default nl
