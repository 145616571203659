import {GeneralContext} from 'types/general'
import en from './en'
import nl from './nl'
import {useContext} from 'react'

const langs = {nl, en}

export type LangType = 'nl' | 'en'

// export const TranslationContext = createContext<LangType>('nl')

export const useTranslation = () => {
	const lang = useContext(GeneralContext)?.lang || 'nl'
	return langs[lang]
}
