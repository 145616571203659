import React, {HTMLProps} from 'react'
import {Mod} from 'types/basictypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Button.module.scss'
import {Icon} from './Icon'

const styles = fromModule(css)

type ButtonModType = {
	mod?: Mod<
		| 'small'
		| 'large'
		| 'maxwidth'
		| 'fullwidth'
		| 'outline'
		| 'inherit'
		| 'bgdark'
		| 'grey'
	>
}

type ButtonIconType = 'add' | 'arrow_right_large' | 'checkmark'

export const Button: React.FC<
	HTMLProps<HTMLAnchorElement> &
		ButtonModType & {
			to?: string
			as?: 'button'
			iconbefore?: ButtonIconType
			iconafter?: ButtonIconType
		}
> = ({as, mod, iconbefore, iconafter, children, ...props}) => {
	const ButtonTag: any = as || Link

	return (
		<ButtonTag
			{...props}
			className={styles.button.mergeProps(props).mod(mod)()}
		>
			{iconbefore && <ButtonIcon icon={iconbefore} />}
			<ButtonText>{children}</ButtonText>
			{iconafter && <ButtonIcon icon={iconafter} />}
		</ButtonTag>
	)
}

const ButtonText: React.FC = (props) => (
	<span {...props} className={styles.button.text()} />
)

const ButtonIcon: React.FC<{icon: ButtonIconType}> = ({icon}) => (
	<span className={styles.button.icon()}>
		<Icon icon={icon} />
	</span>
)
