import * as React from 'react'

import NextLink from 'next/link'
import {useRouter} from 'next/dist/client/router'

// allow this component to accept all properties of "a" tag
interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	to?: string
	// we can add more properties we need from next/link in the future
}

/**
 * Loosely based on https://blog.logrocket.com/dealing-with-links-in-next-js/
 */
export const Link = React.forwardRef(function Link(
	{to, target, children, ...props}: IProps,
	ref: any
) {
	if (!to) return <a {...props}>{children}</a>

	if (isExternal(to)) {
		return (
			<a {...props} href={to} ref={ref} target={target || '_blank'}>
				{children}
			</a>
		)
	}

	if (/^#/.test(to)) {
		return (
			<a
				{...props}
				ref={ref}
				target={'_self'}
				onClick={(e) => {
					e.preventDefault()
					scrollToHash(to)
				}}
			>
				{children}
			</a>
		)
	}

	return (
		<NextLink href={to}>
			<a {...props} ref={ref} target={target || '_self'}>
				{children}
			</a>
		</NextLink>
	)
})

function isExternal(to: string) {
	if (to.startsWith && !to.startsWith('http')) return false
	return true
}

export function scrollToHash(to, yOffset = -25) {
	const hash = to && to.substring(1)
	if (!hash) return

	const el: HTMLElement | null = document.getElementById(hash)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset

	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 0)
}

export const useShallowLinker = () => {
	const router = useRouter()

	return (query: Record<string, any>) => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					...query,
					slug: router.query.slug
				}
			},
			undefined,
			{shallow: true}
		)
	}
}
