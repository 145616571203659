import {createContext, useContext} from 'react'
import {
	DPFooterTerm,
	DPFullNode,
	DPHierarchy,
	DPLink,
	DPMainMenuTerm,
	DPNode,
	DPSecondaryMenuTerm,
	DPUrl
} from './DPTypes'

export type GeneralData = {
	links: {
		home: string
		articles: string
		basket: string
		calendar: string
		contact: string
		projects: string
		search: string
	}
	main_menu: DPHierarchy<DPMainMenuTerm>
	secondary_menu: DPSecondaryMenuTerm[]
	footer: DPFooterTerm
}

export type GeneralContextData = GeneralData & {
	node?: DPFullNode
	lang: 'nl' | 'en'
}

export const GeneralContext = createContext<GeneralContextData>(null as any)

type MenuItem = {link: DPLink; children?: DPHierarchy<{link: DPLink}>}

export const useMenuItemIsActive = () => {
	const {node: page, links} = useContext(GeneralContext)
	return {
		check: (item: MenuItem) => checkIfPageIsActive(page, item, links)
	}
}

export const checkIfPageIsActive = (
	page: DPNode | DPUrl,
	item: MenuItem,
	links: GeneralData['links']
) => {
	if (!page) return false
	let pageUrl = typeof page === 'string' ? page : page.url
	if (!pageUrl) return false
	pageUrl = pageUrl.split(/[?#]/)[0]

	if (pageUrl === item.link?.url) return true
	if (
		(item.children || []).find((child) =>
			checkIfPageIsActive(page, child, links)
		)
	) {
		return true
	}

	if (typeof page === 'string') return false
	switch (page.type) {
		case 'page':
			if (page.url === links.contact) return false
			return checkIfPageIsActive(page.parent, item, links)
		case 'roadmap':
			return checkIfPageIsActive(page.program, item, links)
		case 'concept':
			return checkIfPageIsActive(page.roadmap, item, links)
		case 'article':
			return checkIfPageIsActive(
				page.article_type?.overview_page?.url,
				item,
				links
			)
		case 'project':
			return checkIfPageIsActive(links.projects, item, links)
		case 'event':
			return checkIfPageIsActive(links.calendar, item, links)
		case 'contact':
			return checkIfPageIsActive(links.contact, item, links)
	}

	return false
}
