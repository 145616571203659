import React, {HTMLProps} from 'react'
import {Mod} from 'types/basictypes'
import {fromModule} from 'util/styler/Styler'
import css from './Title.module.scss'

const styles = fromModule(css)

type Heading = React.FC<
	HTMLProps<HTMLHeadingElement> & {as?: any; mod?: Mod<HeadingMods>}
>
type HeadingMods = 'bold' | 'inherit'

const H1: Heading = ({as: Tag = 'h1', mod, ...props}) => (
	<Tag
		{...props}
		className={styles.title.mod('h1').mod(mod).mergeProps(props)()}
	/>
)
const H2: Heading = ({as: Tag = 'h2', mod, ...props}) => (
	<Tag
		{...props}
		className={styles.title.mod('h2').mod(mod).mergeProps(props)()}
	/>
)
const H3: Heading = ({as: Tag = 'h3', mod, ...props}) => (
	<Tag
		{...props}
		className={styles.title.mod('h3').mod(mod).mergeProps(props)()}
	/>
)
const H4: Heading = ({as: Tag = 'h4', mod, ...props}) => (
	<Tag
		{...props}
		className={styles.title.mod('h4').mod(mod).mergeProps(props)()}
	/>
)
const H5: Heading = ({as: Tag = 'h5', mod, ...props}) => (
	<Tag
		{...props}
		className={styles.title.mod('h5').mod(mod).mergeProps(props)()}
	/>
)

const Marked: React.FC<
	HTMLProps<HTMLHeadingElement> & {
		as?: any
		mod?: Mod<'small' | 'normal' | 'large'>
	}
> = ({as: Tag = 'h3', mod, ...props}) => {
	return (
		<Tag
			{...props}
			className={styles.title_marked.mergeProps(props).mod(mod)()}
		/>
	)
}

export const Title = {H1, H2, H3, H4, H5, Marked}
