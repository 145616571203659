import React from 'react'
import {Mod} from 'types/basictypes'
import {fromModule} from 'util/styler/Styler'
import css from './Container.module.scss'

const styles = fromModule(css)

export const Container: React.FC<{
	mod?: Mod<'small'>
}> = ({mod, children}) => (
	<div className={styles.container.mod(mod)()}>{children}</div>
)
