import nl from './nl'

export const en = {
	...nl,
	lang: 'en' as const,
	project_name: "Flanders' FOOD",
	overview: {
		searchbox: {
			placeholder: 'Search by keyword'
		},
		filters: {
			title: 'Filters',
			clear: 'Clear filters',
			tags: 'Keywords'
		},
		pagination: {
			of: 'of',
			previous: 'previous',
			next: 'next'
		}
	},
	programs: {
		discover: 'Discover the program and the projects'
	},
	contact: {
		back: 'Back to contact page',
		more_info: 'More info or interest?',
		send_mail: 'Send an email'
	},
	projects: {
		tab_running: 'Current projects',
		tab_finished: 'Past projects',
		categories: 'Programs and roadmaps',
		region: 'Type',
		domains: 'Strategic domain'
	},
	project: {
		load_more: 'Load more projects',
		show_all: 'Show all projects',
		discover: 'Discover project',
		type: 'Project type',
		start_date: 'Start',
		end_date: 'End',
		resultsLabel: (nr: number) => (nr === 1 ? '1 project' : `${nr} projects`),
		share: 'Share this project'
	},
	calendar: {
		tab_flandersfood: "Flanders' FOOD activities",
		tab_all: 'All activities from the cluster',
		all: 'All',
		this_week: 'This week',
		this_month: 'This month',
		next_month: 'Next month',
		finished: 'Finished',
		branding: 'Branding',
		regions: 'Location',
		dates: 'Date',
		price: 'Price'
	},
	events: {
		show_all: 'Show all activities',
		brandings: 'Branding',
		pricings: 'Prices',
		resultsLabel: (nr: number) => (nr === 1 ? '1 activity' : `${nr} activities`)
	},
	event: {
		program: 'Program',
		practicals: {
			title: 'Practical information',
			when: 'When',
			where: 'Where',
			budget: 'Budget',
			language: 'Language',
			organisor: 'Organizer'
		},
		subscribe: 'Subscribe',
		share: 'Share this event',
		branding: {
			icon: 'Event branding icon'
		}
	},
	article: {
		author: 'Author',
		published_on: 'Published on',
		show_all: 'All articles',
		share: 'Share this article'
	},
	concept: {
		readmore: 'Read more'
	},
	product: {
		show_all: 'Show all products',
		price: 'Price',
		subtext: `Prices are exclusive of VAT and include shipping costs within the EU`,
		add: 'Order product',
		added: 'Added',
		show_in_basket: (quantity: number) =>
			`Show in shopping basket (${quantity})`
	},
	shop: {
		show_basket: 'Show basket',
		is_added: 'has been added to your basket.'
	},
	basket: {
		no_products: 'You currently have no products in the shopping basket.',
		excl_vat: '(excl. VAT)',
		show: 'Show basket',
		delete: 'Remove from basket',
		total: 'Total',
		info: 'Prices are exclusive of VAT and include shipping costs within the EU',
		submit: 'Complete order'
	},
	newsletter: {
		placeholder: 'Email address',
		button: 'Subscribe'
	},
	homepage: {
		arialabel: 'Go to the homepage'
	},
	search: {
		arialabel: 'Go to the search page'
	},
	mobilemenu: {
		open: 'Open mobile menu',
		close: 'Close mobile menu'
	},
	carousel: {
		gotoprev: 'Go to previous',
		gotonext: 'Go to next'
	},
	cookies: {
		optout: {
			text: (category: string) =>
				`Accept ${category}-cookies to display this content.`,
			button: 'Cookie settings'
		}
	},
	error404: {
		title: 'Page not found',
		text: `The page you were looking for might have been renamed, moved, deleted, or may never have existed at all.`,
		btnlabel: 'Go to homepage'
	},
	dates: {
		at: 'at',
		from: 'from',
		to: 'to'
	},
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
}
export default en
